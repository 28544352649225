import { VariantType } from '../CartItem';
import { Thumbnail } from '../Thumbnail';
import Dinero from 'dinero.js';
import dayjs from 'dayjs';
import { ExpectedDelay, ExpectedDelayDTO } from './ExpectedDelay';
import { toDinero } from '../../formatFun';

export class Variant {
    description: string;
    discountPrice: number;
    fullPrice: number;
    id: number;
    isComingSoon: boolean;
    isDiscounted: boolean;
    isOnSale: boolean;
    isPreOrder: boolean;
    price: Dinero.Dinero;
    priceType: string;
    saleStatus: string;
    scheduleDate: Date;
    sku: string;
    stockCount: number; //This is not currently used. Here for legacy reasons.
    isInStock: boolean;
    thumbnails: Thumbnail[];
    type: VariantType;
    usesDefaultProductImage: boolean;
    expectedDelay: ExpectedDelay;
    orderLimit?:number;
    cartLimit?:number;
    isNotAvailableInUserRegion: boolean;

    toApi(): VariantDTO {
        // make sure to null-check if some fields are optional.
        const dtoScheduleDate = dayjs(this.scheduleDate).isValid() ? dayjs(this.scheduleDate).toISOString() : null;

        return Object.assign({}, this, {
            price: this.price?.getAmount(),
            scheduleDate: dtoScheduleDate,
            expectedDelay: this.expectedDelay?.toApi(),
            currency: this.price?.getCurrency().toString()
        });
    }

    static createFromApi(dto: VariantDTO): Variant {
        // make sure to null-check if some fields are optional.
        const scheduleDateObject = dto.scheduleDate ? dayjs(dto.scheduleDate).toDate() : null;
        return Object.assign(new Variant(), dto, {
            price: toDinero(dto.price, dto.currency),
            scheduleDate: scheduleDateObject,
            expectedDelay: ExpectedDelay.createFromApi(dto.expectedDelay),
        });
    }
    
    static IsPurchaseable(variant: Variant): boolean {
        switch (variant?.priceType) {
            case "FreeToReadAndPurchaseble":
            case "FreeToReadForRegisteredAndPurchaseble":
            case "Paid":
                return true;
            default:
                return false;
        }
    }

    static IsReadable(variant: Variant): boolean {
        switch (variant?.priceType) {
            case "FreeToReadAndPurchaseble":
            case "FreeToReadForRegisteredAndPurchaseble":
            case "Free":
            case "FreeForRegistered":
                return true;
            default:
                return false;
        }
    }
}

export interface VariantDTO {
    id: number;
    type: VariantType;
    description: string;
    price?: number;
    isDiscounted: boolean;
    fullPrice: number;
    currency: string;
    stockCount: number;
    isInStock: boolean;
    thumbnails: Thumbnail[];
    usesDefaultProductImage: boolean;
    sku: string;
    isOnSale: boolean;
    saleStatus: string;
    discountPrice: number;
    isComingSoon: boolean;
    isPreOrder: boolean;
    scheduleDate: string;
    expectedDelay: ExpectedDelayDTO;
    orderLimit?:number;
    cartLimit?:number;
    isNotAvailableInUserRegion: boolean;
}
