import { CartItem, CartItemDTO, VariantType } from './CartItem';
import { User } from './User';
import Dinero, { Currency } from 'dinero.js';
import { Variant } from './Product/Variant';
import { Product } from './Product';


export enum CartStatus {
    Open,
    Purchasing,
    Closed
}

function cartStatusFromString(text: string): CartStatus {
    switch (text) {
        case "Open": return CartStatus.Open;
        case "Purchasing": return CartStatus.Purchasing;
        case "Closed": return CartStatus.Closed;
    }

    return null;
}

export class Cart {
    id: number;
    userId?: number;
    user: User;
    guestId?: string;
    cartItems: CartItem[] = [];
    status: CartStatus;
    total: Dinero.Dinero;
    // currency: string;

    setStatus(newStatus: CartStatus): Cart {
        return Object.assign(new Cart(), this, {
           status: newStatus
        });
    }

    containsProductVariant(product: Product, variant: Variant): boolean {
        return this.cartItems.some(x => x.productId === product.id && x.variant.id === variant.id);
    }

    amountOfProductVariant(product: Product, variant: Variant): number {
        return this.cartItems.filter(x => x.productId === product.id && x.variant.id === variant.id).length;
    }
    containsProductVariantType(variantType: VariantType): boolean {
        return this.cartItems.some(x => x.variant.type=== variantType);
    }

    /**
     * Intented to assist in comping carts that only have 0.00 sale products, should only be digital products
     */
    isFreeOrder(){
        if(this.containsProductVariantType(VariantType.Print)){
            return false;
        }
        return this.total.getAmount() === 0;
        
    }

    //TODO: Replace with DTO when we get total from backend
    // static getCartTotal(cart: Cart): Dinero.Dinero{
    //     let total: Dinero.Dinero = null;
    //     if(cart?.cartItems){
    //         cart.cartItems.forEach(x => {
    //             if(total) {
    //                 total = total.add(x.price);
    //             }
    //             else {
    //                 total = x.price;
    //             }
    //         });
    //     }
    //     return total ?? Dinero({amount: 0, currency: 'USD'});
    // }



    static createFromApi(dto: CartDTO): Cart{
        // console.log("CartItems are", dto.cartItems)
        return Object.assign(new Cart(), dto, {
            cartItems: dto.cartItems.map(x => CartItem.createFromApi(x)),
            status: cartStatusFromString(dto.status),
            total: !dto.currency ? null : Dinero({
                amount: Math.round((dto.total ?? 0) * 100),
                currency: dto.currency as Currency,
            })
        });
    }
}

export class CartDTO {
    id: number;
    userId?: number;
    user: User;
    guestId?: number;
    cartItems: CartItemDTO[] = [];
    status: string;
    total: number;
    currency: string;
}
