import React, { ReactElement } from 'react';
import './InkyPenArrow.scss';
import { ReactComponent as Chevron } from '../../Assets/inky-icons/Chevron_InkyPen.svg';
// import SvgArrow from './SvgArrow';

// ignoreActive is a value for trigger onClick on every situation regardless the button is not active
interface InkyArrowProps {
    text: string;
    withArrow?: boolean;
    active?: boolean;
    bold?: boolean;
    invert?: boolean;
    fill?: boolean;
    ignoreActive?: boolean;
    styles?: string;
    onClick?: (...args) => void;
    title?: string;
}

const InkyPenArrow = ({
                          onClick,
                          active = true,
                          text,
                          withArrow = false,
                          styles = '',
                          ignoreActive = false,
                          bold,
                          invert = false,
                          fill = false,
                          title,
                      }: InkyArrowProps): ReactElement => {
    const onButtonClicked = (...args): void => {
        if (active && onClick || ignoreActive) {
            onClick(...args);
        }
    };

    function getClassName() {
        let className = styles;
        if (!active) {
            className += ' disabled';
        }
        if (invert) {
            className += ' invert';
        }

        return className;
    }

    return (
        <div role={'button'}
             onClick={onButtonClicked}
             className={`arrow-container ${getClassName()} ${fill ? "inky-button-fill" : ""}`}
             aria-label={text}
             title={title}
        >
            <span
                className={`arrow-btn ${withArrow ? 'with-arrow' : 'no-arrow'} text-bold ${bold && 'text-bold'}`}>{text}</span>
            {withArrow && <div className='icon-wrapper' >
                <Chevron className={'chevron-icon'}/>
            </div>}
        </div>
    );
};

export default InkyPenArrow;
