import React from 'react';
import './InkyPenButton.scss';

import { ReactComponent as Chevron } from '../Assets/inky-icons/Chevron_InkyPen.svg';

interface InkyButtonProp {
    icon:
        'Add' |
        'Anime' |
        'Art Book' |
        'Books' |
        'Data' |
        'Deals' |
        'Direction' |
        'Direction_No_Border' |
        'Document' |
        'Events' |
        'File' |
        'Games' |
        'Giveaway' |
        'Level' |
        'Live Action' |
        'Location' |
        'Magazine' |
        'Mail' |
        'Manga' |
        'Map' |
        'Men' |
        'Music' |
        'Novel' |
        'Outdoors' |
        'Photo' |
        'Picture Book' |
        'Pin' |
        'Quote' |
        'Reading Room' |
        'Returns' |
        'Sales' |
        'Shipping' |
        'Sport' |
        'Stairs' |
        'Survey' |
        'Sweepstakes' |
        'Weather' |
        'Women';
    iconOrientation?: '0' | '90' | '180' | '270';
    children?: string;
    /// NB! This is NOT for styles, but for classnames
    styles?: string;
    size?: string;
    reverse?: boolean;
}


export function InkyPenButton(props: InkyButtonProp): JSX.Element {
    const orientation = props.iconOrientation ?? '0';


    // WIth icon
    if (props.icon) {

        // WIthouht text
        if (!props.children) {

            if(props.icon == "Direction"){
               return( <div className={'InkyPenButton ' + props.icon + ` ${props.styles ?? ""}`} style={{ transform: 'rotate(' + (parseInt(orientation) - 180).toString()  + 'deg)' }}>
                   <Chevron />
               </div>);
            }


            return (
                <div className={'InkyPenButton ' + props.icon + ` ${props.styles ?? ""}`}>
                    <img alt='more-button' style={{ transform: 'rotate(' + orientation + 'deg)' }}
                         src={require('./Assets/' + props.icon + '_Kodansha.svg')} />
                </div>
            );
        }

        return (
            <div className={'InkyPenButton ' + props.icon + ` ${props.styles}`}
                 style={{ border: 'none', height: props.size && props.size }}>
                {!props.reverse ?
                    <>


                        <div className={'label has-icon not-reverse'}>{props.children}</div>

                        {/*<img style={{width: props.size, height: props.size, transform: "rotate("+ orientation +"deg)"}} src={require('./Assets/' + props.icon +'_Kodansha.svg')}/>*/}
                        <img alt='more-button' style={{ width: props.size, height: props.size }}
                             src={require('./Assets/' + props.icon + '_Kodansha.svg')} />
                    </>

                    :

                    <>
                        <img alt='more-button' style={{ width: props.size, height: props.size, border: 'none' }}
                             src={require('./Assets/' + props.icon + '_Kodansha.svg')} />

                        <div className={'label has-icon is-reverse'}>{props.children}</div>
                    </>
                }

            </div>
        );
    }

}

