


export class SentEmails {
    id: number;
    externalId: string;
    events: SentEmailEvent[];
    mailCreatedDateTime: Date;
    from: string;
    to: string;
    subject: string;

    static createFromApi(dto: SentEmailDTO): SentEmails {
        return {
            id: dto.id,
            externalId: dto.externalId,
            events: dto.events.map(SentEmailEvent.createFromApi),
            mailCreatedDateTime: dto.mailCreatedDateTime ? new Date(dto.mailCreatedDateTime) : null,
            from: dto.from,
            to: dto.to,
            subject: dto.subject
        };
    }
}

export class SentEmailEvent {
    id: number;
    sentEmailId: number;
    eventType: 'Send' | 'Delivery' | 'Open' | 'Click' | 'Bounce' | 'Complaint' | 'Unsubscribe' | 'RenderingFailure' | 'Reject';
    eventDateTime: Date;
    completeEventString: string;

    static createFromApi(dto: SentEmailEventDTO): SentEmailEvent {
        return {
            id: dto.id,
            sentEmailId: dto.sentEmailId,
            eventType: dto.eventType,
            eventDateTime: dto.eventDateTime ?  new Date(dto.eventDateTime): null,
            completeEventString: dto.completeEventString
        };
    }
}


export type SentEmailDTO = {
    id: number;
    externalId: string;
    events: SentEmailEventDTO[];
    mailCreatedDateTime: string;
    from: string;
    to: string;
    subject: string;
}

export type SentEmailEventDTO = {
    id: number;
    sentEmailId: number;
    eventType: "Send" | "Delivery" | "Open" | "Click" | "Bounce" | "Complaint" | "Unsubscribe";
    eventDateTime: string;
    completeEventString: string;
}

export type SentEmailMetricsRequest = {
    dateFrom?: string,
    dateTo?: string
}

export type SentEmailMetrics = {
    emails: {
        subTotals: {
            emailSubject: string,
            count: number
        }[],
        total: number
    },
    events: {
        subTotals: {
            date: string,
            subTotals: {
                SendEvent: number,
                DeliveryEvent: number,
                OpenEvent: number,
                ClickEvent: number,
                BounceEvent: number,
                ComplaintEvent: number,
                UnsubscribeEvent: number,
                RenderingFailureEvent: number,
                RejectEvent: number
            },
            daylyTotal: number
        }[],
        total:
            number
    },
    dateFrom: string,
    dateTo: string
}

/*
{
    "response": {
        "emails": {
            "subTotals": [
                {
                    "emailSubject": "Your InkyPen Password been updated",
                    "count": 28
                },
                {
                    "emailSubject": "Thank you for signing up",
                    "count": 31
                },
                {
                    "emailSubject": "Welcome to InkyPen!",
                    "count": 27
                },
                {
                    "emailSubject": "Reset your password",
                    "count": 5
                },
                {
                    "emailSubject": "InkyPen: Only 11 days left",
                    "count": 2
                },
                {
                    "emailSubject": "You are now Subscribed",
                    "count": 16
                },
                {
                    "emailSubject": "Message sent from Amazon SES",
                    "count": 1
                },
                {
                    "emailSubject": "Your InkyPen subscription failed to renew",
                    "count": 1
                },
                {
                    "emailSubject": "Your subscription has been cancelled",
                    "count": 1
                },
                {
                    "emailSubject": "Now Launching on Steam!",
                    "count": 7
                }
            ],
            "total": 119
        },
        "events": {
            "subTotals": [
                {
                    "date": "2025-01-06",
                    "subTotals": {
                        "SendEvent": 30,
                        "DeliveryEvent": 30,
                        "OpenEvent": 7,
                        "ClickEvent": 2
                    },
                    "daylyTotal": 69
                },
                {
                    "date": "2025-01-07",
                    "subTotals": {
                        "SendEvent": 14,
                        "OpenEvent": 14,
                        "DeliveryEvent": 14,
                        "ClickEvent": 3
                    },
                    "daylyTotal": 45
                },
                {
                    "date": "2025-01-08",
                    "subTotals": {
                        "SendEvent": 33,
                        "OpenEvent": 12,
                        "DeliveryEvent": 33,
                        "ClickEvent": 4
                    },
                    "daylyTotal": 82
                },
                {
                    "date": "2017-08-05",
                    "subTotals": {
                        "BounceEvent": 2,
                        "ComplaintEvent": 1
                    },
                    "daylyTotal": 3
                },
                {
                    "date": "2016-10-14",
                    "subTotals": {
                        "RejectEvent": 1,
                        "SendEvent": 1
                    },
                    "daylyTotal": 2
                },
                {
                    "date": "2017-08-10",
                    "subTotals": {
                        "OpenEvent": 1,
                        "ClickEvent": 1
                    },
                    "daylyTotal": 2
                },
                {
                    "date": "2016-10-20",
                    "subTotals": {
                        "DeliveryEvent": 1
                    },
                    "daylyTotal": 1
                },
                {
                    "date": "2025-01-09",
                    "subTotals": {
                        "SendEvent": 40,
                        "DeliveryEvent": 40,
                        "OpenEvent": 27,
                        "ClickEvent": 10
                    },
                    "daylyTotal": 117
                }
            ],
            "total": 321
        },
        "dateFrom": "0001-01-01",
        "dateTo": "9999-12-31"
    },
    "status": {
        "type": "Success"
    }
}
* */