/**
 * https://www.youtube.com/watch?v=dDHErN3dOkc
 */

import Dinero, { Currency } from 'dinero.js';

/**
 * @throws TypeError if amount cannot be converted to a number.
 */
export function formatPercentage(amount: string | number, locale='en-US'): string {
    const a = Number(amount);
    if (Number.isNaN(a))
        throw new TypeError(`${a} cannot be converted to a number`);

    return new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
    }).format(a);
}

/**
 * ToDO: Check if this is needed any more with Dinero library? (Dinero still formats currency amounts strangely)
 * @throws TypeError if amount cannot be converted to a number.
 */
export function formatCurrency(amount: string | number,
                               currency='USD',
                               locale='en-US'): string {
    const a = Number(amount);
    if (Number.isNaN(a))
        throw new TypeError(`${a} cannot be converted to a number`);

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
    }).format(a);
}

export function toDinero(amount: number, currency: string): Dinero.Dinero|null {
    if(currency === null || currency === undefined) {
        return null;
    }

    return Dinero({
        amount: Math.round((amount ?? 0) * 100),
        currency: (currency as Currency)});
}

export function toDineroUsd(amount: number): Dinero.Dinero {
    if (amount === null || amount === undefined) return null;

    return Dinero({
        amount: Math.round(amount * 100),
        currency: 'USD'
    });
}
