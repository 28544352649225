export class ScheduledEmail{
    id: number;
    receipientEmail: string;
    templateName: string;
    sendFromDateTime: Date;
    sentAtDateTime: Date;
    status:"Scheduled"| "Sending" | "Sent" | "Failed" | "Cancelled";
    timeCreated: Date;
    lastEdited: Date;
    createdBy: string;
    editedBy: string;
    messageId: string;

    static createFromApi(dto: ScheduledEmailDTO): ScheduledEmail{
        return {
            id: dto.id,
            receipientEmail: dto.receipientEmail,
            templateName: dto.templateName,
            sendFromDateTime: new Date(dto.sendFromDateTime),
            sentAtDateTime: new Date(dto.sentAtDateTime),
            status: dto.status,
            timeCreated: new Date(dto.timeCreated),
            lastEdited: new Date(dto.lastEdited),
            createdBy: dto.createdBy,
            editedBy: dto.editedBy,
            messageId: dto.messageId
        }
    }
}

export type ScheduledEmailDTO = {
    id: number;
    receipientEmail: string;
    templateName: string;
    sendFromDateTime: string;
    sentAtDateTime: string;
    status:   "Scheduled"| "Sending" | "Sent" | "Failed" | "Cancelled";
    timeCreated: string;
    lastEdited: string;
    createdBy: string;
    editedBy: string;
    messageId: string;
}

export type ScheduleEmailMetrics = {
    fromDate: string;
    toDate: string;
    totals: {
        totalScheduledEmails: number;
        scheduledEmails: number;
        sendingEmails: number;
        sentEmails: number;
        failedEmails: number;
        cancelledEmails: number;
    },
    templateTotals: {
        templateName: string;
        totalScheduledEmails: number;
        scheduledEmails: number;
        sendingEmails: number;
        sentEmails: number;
        failedEmails: number;
        cancelledEmails: number;
    }[]
}

export type ScheduleBudget = {
    budgetTimeSpans: ScheduleBudgetTimeSpan[];
    fullQuota: number;
    reserveCutoff: number;
    totalReservedQuota: number;
    minBudget: number;
}
export type ScheduleBudgetTimeSpan = {
    hours: number;
    reservedQuota: number;
    used: number;
    remaining: number;
}

//*
// {
//     "response": {
//         "budgetTimeSpans": [
//             {
//                 "hours": 24,
//                 "reservedQuota": 100,
//                 "used": 27,
//                 "remaining": 73
//             },
//             {
//                 "hours": 12,
//                 "reservedQuota": 50,
//                 "used": 12,
//                 "remaining": 38
//             },
//             {
//                 "hours": 6,
//                 "reservedQuota": 25,
//                 "used": 12,
//                 "remaining": 13
//             },
//             {
//                 "hours": 3,
//                 "reservedQuota": 12,
//                 "used": 12,
//                 "remaining": 0
//             },
//             {
//                 "hours": 1,
//                 "reservedQuota": 4,
//                 "used": 4,
//                 "remaining": 0
//             }
//         ],
//         "fullQuota": 200,
//         "reserveCutoff": 0.5,
//         "totalReservedQuota": 100,
//         "minBudget": 0
//     },
//     "status": {
//         "type": "Success"
//     }
// }
//
// */